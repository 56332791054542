import React from 'react'
import PropTypes from 'prop-types'
import PageTransitionLink from 'components/PageTransitionLink'

import Section from 'components/Section'
import {
  Wrapper,
  NavigationSection,
  NavigationTitle,
  P,
  Underline,
} from './styles'

const renderLink = (props, direction = '') => {
  const slug = props.node.elements.slug
  const title = props.node.elements.title
  return (
    <PageTransitionLink to={`/work/${slug.value}`}>
      <NavigationSection width={null}>
        <Section px={30} my="auto" width={null}>
          <NavigationTitle>
            {direction === `previous` && <P>←&nbsp;</P>}
            <Underline>{title.value}</Underline>
            {direction === `next` && <P>&nbsp;→</P>}
          </NavigationTitle>
        </Section>
      </NavigationSection>
    </PageTransitionLink>
  )
}

function renderPreviousNextSection(allCaseStudies, caseStudySlug) {
  const activeCaseStudy = allCaseStudies.find(
    (study) => study.node.elements.slug.value == caseStudySlug
  )
  const activeCaseStudyIndex = allCaseStudies.indexOf(activeCaseStudy)
  const indexOfLastCaseStudyInArr = allCaseStudies.length - 1

  let previous
  let next

  if (activeCaseStudy === allCaseStudies[0]) {
    previous = allCaseStudies.pop()
    next = allCaseStudies[activeCaseStudyIndex + 1]
  } else if (activeCaseStudy === allCaseStudies[indexOfLastCaseStudyInArr]) {
    previous = allCaseStudies[activeCaseStudyIndex - 1]
    next = allCaseStudies[0]
  } else {
    previous = allCaseStudies[activeCaseStudyIndex - 1]
    next = allCaseStudies[activeCaseStudyIndex + 1]
  }

  return (
    <Section>
      <Wrapper previous={!!previous} next={!!next}>
        {previous && renderLink(previous, 'previous')}
        {next && renderLink(next, 'next')}
      </Wrapper>
    </Section>
  )
}

export default renderPreviousNextSection

renderLink.propTypes = {
  elements: PropTypes.node,
  node: PropTypes.object,
}
