import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import LazyLoad from 'react-lazyload'
import ScrollAnimation from 'react-animate-on-scroll'
import Layout from 'layouts/Default'
import PageSection from 'components/Section/pageSection'
import Headline from 'components/Headline'
import Section from 'components/Section'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import renderPreviousNextSection from './renderPreviousNextSection'
import {
  IntroText,
  BodyText,
  WorkContainer,
  Underline,
  WorkTagline,
  Testimonial,
  Quote,
  Author,
} from './styles'
import 'resolvers/ImageGrid/style.css'

const PageSectionStyled = styled(PageSection)`
  overflow: hidden;
`

const WorkDetails = (props) => {
  const { location } = props
  const allCaseStudies = props.data.allKenticoCloudItemProject.edges
  let caseStudieswithPageContent = []
  allCaseStudies.forEach((caseStudy) => {
    if (
      caseStudy.node.elements.link_type.value[0].codename.includes('case_study')
    ) {
      caseStudieswithPageContent.push(caseStudy)
    }
  })

  const caseStudySlug = props.data.kenticoCloudItemProject.fields.slug
  const {
    title,
    tagline,
    intro_text,
    project_link,
    body_text,
    testimonial,
  } = props.data.kenticoCloudItemProject.elements

  const removeUrlProtocol = project_link.value
    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    .split('/')[0]

  const isTestimonial = testimonial.length > 0

  const buildOGMetadata = () => {
    const { elements } = props.data.kenticoCloudItemProject

    return {
      title: elements.page_metadata__opengraph_title.value,
      imageUrl: elements.page_metadata__opengraph_image.value[0].url,
      description: elements.page_metadata__opengraph_description.value,
    }
  }

  return (
    <Layout {...props} ogMetadata={buildOGMetadata()}>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.GATSBY_ROBOTS_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <PageSectionStyled>
        <WorkContainer>
          <Section pt={90} pb={20}>
            <Headline header>{title.value}</Headline>
            <WorkTagline>{tagline.value}</WorkTagline>
            <IntroText dangerouslySetInnerHTML={{ __html: intro_text.value }} />
            <a
              href={project_link.value}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Underline bold>{removeUrlProtocol}</Underline>
              &nbsp;→
            </a>
            <BodyText
              dangerouslySetInnerHTML={{
                __html: body_text.resolvedHtml,
              }}
            />
          </Section>
        </WorkContainer>
      </PageSectionStyled>
      <PageSection>
        <LazyLoad offset={100}>
          <ScrollAnimation animateIn="fadeIn">
            {isTestimonial && (
              <Testimonial>
                <Quote>
                  &quot;{testimonial[0].elements.full_quote.value}&quot;
                </Quote>
                <Author>
                  <span>
                    {testimonial[0].elements.name.value},&nbsp;
                    {testimonial[0].elements.job_position.value},&nbsp;
                  </span>
                  <span>{testimonial[0].elements.company.value}</span>
                </Author>
              </Testimonial>
            )}
          </ScrollAnimation>
        </LazyLoad>
      </PageSection>
      {renderPreviousNextSection(caseStudieswithPageContent, caseStudySlug)}
    </Layout>
  )
}

export default WorkDetails

export const query = graphql`
  query projectsQuery($slug: String!) {
    allKenticoCloudItemProject {
      edges {
        node {
          elements {
            link_type {
              value {
                codename
              }
            }
            title {
              value
            }
            slug {
              value
            }
            page_metadata__opengraph_title {
              name
              value
              type
              text
            }
            page_metadata__opengraph_image {
              value {
                url
              }
            }
            page_metadata__opengraph_description {
              value
            }
          }
        }
      }
    }
    kenticoCloudItemProject(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      elements {
        title {
          value
        }
        tagline {
          value
        }
        intro_text {
          value
        }
        body_text {
          value
          resolvedHtml
        }
        testimonial {
          elements {
            full_quote {
              value
            }
            name {
              value
            }
            job_position {
              value
            }
            company {
              value
            }
          }
        }
        project_link {
          value
        }
        link_text {
          value
        }
        page_metadata__opengraph_title {
          value
        }
        page_metadata__opengraph_image {
          value {
            url
          }
        }
        page_metadata__opengraph_description {
          value
        }
      }
    }
  }
`

WorkDetails.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}
