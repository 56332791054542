import styled from 'styled-components'
import { Flex } from '@rebass/grid'
import Section from 'components/Section'
import Container from 'components/Container'
import media from 'styles/media'
import Tagline from 'components/Tagline'
import { color } from 'const/colors'
import { animatedLinkStyles } from 'styles/animations'

export const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ previous, next }) =>
    !previous ? 'flex-end' : !next ? 'flex-start' : 'space-between'};
  padding: 0px 10px;
  height: 152px;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);

  ${media.phone`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    height: 100%;
  `}
`

export const WorkTagline = styled(Tagline)`
  margin-bottom: 20px;
`

export const NavigationSection = styled(Section)`
  display: inline-flex;
  cursor: pointer !important;

  ${media.phone`
    height: 152px;
    width: 100vw;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  `}
`

export const NavigationTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;

  ${media.phone`
    text-align: center;
  `}
`

export const Underline = styled.span`
  font-weight: ${({ bold }) => bold && 'bold'};
  ${animatedLinkStyles}

  ${media.tablet`
    display: inline-block;
  `}
`

export const P = styled.p`
  display: inline;
`

const tabletText = `
    font-size: 18px;
    line-height: 28px;
    margin: 0;
  `

export const IntroText = styled.div`
  color: ${color.black.o100};
  font-size: 24px;
  line-height: 40px;

  ${media.tablet`${tabletText}`}
`

export const BodyText = styled.div`
  color: ${color.black.o100};
  font-size: 20px;
  line-height: 28px;
  padding: 75px 0 120px;

  ${media.tablet`${tabletText}`}

  figure img {
    width: 100%;
    margin-left: 50%;
    transform: translateX(-50%);

    ${media.phone`
      width: 110%;
      position: relative;
    `}
  }

  picture img {
    width: 100%;
  }

  figure {
    margin: 100px 0 90px;

    ${media.phone`
      margin: 50px 0 40px;
    `}
  }

  ${media.phone`
    padding: 50px 0 0;
  `}
`

export const WorkContainer = styled(Container)`
  ${media.tablet`
    max-width: 480px;
  `}

  ${media.phone`
    max-width: 312px;
  `}

    ${media.desktop`
    max-width: 580px;
    margin: 0 24px;
   `}
   ${media.desktopLarge`
    max-width: 680px;
    margin: 0 24px;
   `}
`

export const Testimonial = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background: ${color.black.o100};

  ${media.tablet`
    min-height: 370px;
  `}
`

export const Quote = styled.div`
  max-width: 622px;
  padding-bottom: 40px;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: ${color.black.o100};

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
    padding: 0 24px 26px 24px;
  `}

  ${media.phone`
    font-size: 18px;
    line-height: 28px;
    padding: 40px 24px 26px 24px;
  `}
`

export const Author = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: ${color.black.o100};
  text-align: center;

  ${media.tablet`
    font-size: 16px;
    line-height: 20px;
  `}

  ${media.phone`
    span:nth-child(2) {
      display: block;
    }
    padding-bottom: 40px;
  `}
`
